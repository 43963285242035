<template>
  <div id="orderproduct-list">
    <v-card>
      <v-card-title>出荷予定</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="product_code"
            :items="ProductCodes"
            placeholder="商品コード"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateStartMenuPud"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date_pud"
                placeholder="決済日From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="start_date_pud" @input="dateStartMenuPud = false"></v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-col cols="12" md="2">
          <v-menu
            v-model="dateEndMenuPud"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end_date_pud"
                placeholder="決済日To"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="end_date_pud" @input="dateEndMenuPud = false"></v-date-picker>
          </v-menu>
        </v-col> -->
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date"
                placeholder="出荷日From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="start_date" @input="dateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end_date"
                placeholder="出荷日To"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="end_date"
              @input="dateEndMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <!-- <v-btn
            color="primary"
            class="mb-4 me-3"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn> -->
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="tableList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.ord_id`]="{ item }">
          <EstimatedDialog
            :id="item.ord_id"
            @refresh="loadData"
          >
          </EstimatedDialog>
        </template>
        <!-- <template v-slot:[`item.actions`]="{ item }">
          <v-row v-if="!item.processed">
            <v-col style="padding-right: 10px" cols="6">
              <v-icon class="mr-2" small @click="editItem(item)">
                {{ icons.mdiPencil }}
              </v-icon>
            </v-col>
          </v-row>
        </template> -->
        <template v-if="totalSumShow" v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              style="background: #fafafa"
              :colspan="4"
            >
              <label>数量合計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ orderTotalSum }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="3"
            ></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiCalendar,
  mdiMagnify,
  mdiPencil,
  mdiDelete,
  mdiExportVariant,
  mdiAutorenew,
  mdiPlus,
  mdiClipboardArrowUpOutline,
} from '@mdi/js'
import EstimatedDialog from '@/views/bimi/shipping/ShippingEstimated/EstimatedDialog.vue'

export default {
  components: {
    EstimatedDialog,
  },
  data: () => ({
    start_date: '',
    end_date: '',
    start_date_pud: '',
    end_date_pud: '',

    dateEndMenu: false,
    dateStartMenu: false,
    dateStartMenuPud: false,
    dateEndMenuPud: false,
    product_code: '',
    arrBfo: [],
    search: '',
    tableList: [],
    icons: {
      mdiCalendar,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiAutorenew,
      mdiPlus,
      mdiClipboardArrowUpOutline,
    },
    headers: [
      {
        text: 'ID',
        value: 'id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '注文ID',
        value: 'ord_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '商品コード',
        value: 'product_code',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '商品名',
        value: 'product_name',
        width: '33%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '数量',
        value: 'order_qty',
        width: '8%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '決済日',
        value: 'order_pud',
        width: '19%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '出荷予定日',

        value: 'order_ead',
        width: '14%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '商品コード',
        value: 'product_code',
        align: ' d-none',
      },
      {
        text: '姓',
        value: 'mbr_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'mbr_mei',
        align: ' d-none',
      },
    ],
    setCodes: [],
    tableTotalPrice: 0,
    totalSumShow: false,
  }),
  computed: {
    ...mapState('shippingStore', ['EstimatedList']),
    ...mapState('productStore', ['ProductList', 'ProductCodes']),
    orderTotalSum() {
      let totalSum = 0
      this.tableList.forEach(obm => {
        if (obm.order_qty) {
          totalSum += obm.order_qty
        }
      })

      return totalSum.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
  },
  watch: {
    product_code(newValue) {
      this.changeRouteQuery('product_code', newValue)
      this.loadData()
      if (newValue === '' || newValue === null) {
        this.totalSumShow = false
      } else {
        this.totalSumShow = true
      }
    },
    start_date_pud(newValue) {
      this.changeRouteQuery('start_date_pud', newValue)
      this.loadData()
    },

    end_date_pud(newValue) {
      this.changeRouteQuery('end_date_pud', newValue)
      this.loadData()
    },

    start_date(newValue) {
      this.changeRouteQuery('start_date', newValue)
      this.loadData()
    },
    end_date(newValue) {
      this.changeRouteQuery('end_date', newValue)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('shippingStore', ['loadEstimatedList']),
    ...mapActions('productStore', ['loadProductList']),

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadProductList(this.$route.query),
        this.loadEstimatedList(this.$route.query).then(() => {
          this.tableList = [...this.EstimatedList]
        }),
      ]).then(() => {
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    editItem(item) {
      console.log(item)
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      this.start_date_pud = this.$route.query.start_date_pud ?? ''
      this.start_date = this.$route.query.start_date ?? ''
      this.end_date = this.$route.query.end_date ?? ''
      this.product_code = this.$route.query.product_code ?? ''
      // eslint-disable-next-line radix
      this.ordStatus = this.$route.query.os_desc ? parseInt(this.$route.query.os_desc) : ''
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
